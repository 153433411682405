import React from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Login from "./components/Login";

function App() {
  return (
      <Router>
        <Switch>
          <Route path='/login' component={Login} />
        </Switch>
      </Router>
  );
}

export default App;
